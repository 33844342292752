var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "oa-page__wrapper" }, [
    _c(
      "div",
      { staticClass: "page-nav" },
      _vm._l(_vm.childrenPages, function (page) {
        return _c(
          "router-link",
          {
            key: page.name,
            staticClass: "page-nav__link",
            attrs: { to: page.path },
          },
          [_vm._v(_vm._s(page.desc) + " ")]
        )
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }