<template>
  <div class="oa-page__wrapper">
    <div class="page-nav">
      <router-link
        v-for="page in childrenPages"
        class="page-nav__link"
        :key="page.name"
        :to="page.path"
        >{{ page.desc }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'oa-page__wrapper',
  components: {},
  data() {
    return {
      childrenPages: [
        {
          path: '/oa/stamp',
          name: 'oa-stamp',
          desc: '盖章管理'
        },
        {
          path: '/oa/closeCase',
          name: 'oa-close-case',
          desc: '案件管理'
        },
        {
          path: '/oa/finance',
          name: 'oa-finance',
          desc: '财务管理'
        },
        // {
        //   path: '/oa/approval',
        //   name: 'oa-approval',
        //   desc: '审批管理'
        // },
        // {
        //   path: '/oa/closeCase',
        //   name: 'oa-close-case',
        //   desc: '案件管理'
        // },
        {
          path: '/oa/flow',
          name: 'oa-flow',
          desc: '流程管理'
        },
        // {
        //   path: '/oa/invoice',
        //   name: 'oa-invoice',
        //   desc: '发票管理'
        // },
        {
          path: '/oa/approve',
          name: 'oa-approve',
          desc: '审批管理'
        }
      ]
    }
  },
  computed: {},
  methods: {},
  created() {},
  mounted() {}
}
</script>

<style lang="stylus">
.oa-page__wrapper
  position relative
  & .page-nav
    padding 20px
    & .page-nav__link
      display block
      display flex
      margin 0 0 12px
      padding-left 20px
      color #323233
      font-weight 500
      font-size 14px
      line-height 40px
      background #f7f8fa
      border-radius 99px
      &:hover
        background #eef0f4
</style>
